import axios from "axios";
import { User } from "../../../domain/user/user";
import { Configuration, UserList, UserUserResponse, UsersApi } from "../generated";
import { UsersInterface } from "../../../useCase/users/adapters/interface";

export class UsersRepository implements UsersInterface {
    private service: UsersApi;

    constructor() {
        this.service = new UsersApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadUserById(id: UniqueId): Promise<User | Error> {
        try {
            let response = await this.service.apiUserIdGet(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
               


               
                return this.toDomainUser(response.data!)

            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReadUserNameById(id: UniqueId): Promise<string | Error> {
        try {
            let response = await this.service.apiUserIdNameGet(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data.message!

            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }
    
    async ReadUsers(): Promise<User[] | Error> {
        try {
            let response = await this.service.apiUserGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let users: User[] = []
                response.data.forEach(userData => {

                    let user = this.toDomainUser(userData!)
                    users.push(user)
                });
                return users
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async DeleteUser(id: UniqueId): Promise<string | Error> {
        try {
            let response = await this.service.apiUserIdDelete(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "success"
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async CreateUser(name: string, login: string, pass: string, role: string, mentodIsId: number, mentodIs2Id: number, headOperatorId: number, isActive: boolean): Promise<User | Error> {
        try {
            let response = await this.service.apiUserPost({name: name, login: login, pass: pass, role: role, mentor_is_id: mentodIsId, mentor_is2_id: mentodIs2Id, head_operator_id: headOperatorId, is_active: isActive}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
               
                return this.toDomainUser(response.data!)
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async UpdateUser(id: UniqueId, name: string, login: string, role: string, mentodIsId: number, mentodIs2Id: number, headOperatorId: number, isActive: boolean): Promise<User | Error> {
        try {
            let response = await this.service.apiUserIdPut(id, {name: name, login: login, role: role, mentor_is_id: mentodIsId, mentor_is2_id: mentodIs2Id, head_operator_id: headOperatorId, is_active: isActive}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return this.toDomainUser(response.data!)
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ChangePassword(id: UniqueId, pass: string): Promise<User | Error> {
        try {
            let response = await this.service.apiUserIdPasswordPut(id, {password: pass}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
               return this.toDomainUser(response.data!)
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadDictionaries(): Promise<UserList | Error> {
        try {
            let response = await this.service.apiUserDictionaryGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                
                return response.data!
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    toDomainUser = (input: UserUserResponse): User => {
        return new User(input.id!, input.name!, input.login!, input.pass!, input.role!, input.mentor_is_id!, input.mentor_is2_id!, input.head_operator_id!, input.is_active!, new Date(input.created_at!), new Date(input.modified_at!))
    }

}